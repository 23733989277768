<template>
  <v-app-bar app color="#fff" class="elevation-0">
    <v-app-bar-nav-icon
      @click="toggleDrawer()"
      v-if="$route.path != '/didnPbx'"
      src="https://picsum.photos/1920/1080?random"
    ></v-app-bar-nav-icon>

    <h3
      class="font-weight-medium d-none d-md-block"
      v-if="$route.path != '/didnPbx'"
    >
      {{ $route.name }}
    </h3>
    <!-- Back Button -->
    <div class="d-flex align-center" v-if="$route.path == '/didnPbx'">
      <img src="../../../public/DIDnPBX.png" width="70" alt="DIDnPBX" />
      <v-btn color="info" outlined small class="ml-3" @click="backToHome">
        <span class="pr-1 font-weight-medium"> Back </span>
        <v-icon dark class="" color="info" size="22"> west </v-icon>
      </v-btn>
    </div>

    <v-spacer></v-spacer>

    <div class="d-none d-md-block">
      <!-- <v-list-item-action class="mr-2">
        <v-icon color="info">monetization_on </v-icon>
      </v-list-item-action> -->
      <!-- <v-list-item-title><v-icon color="info">monetization_on </v-icon> {{ credit }}</v-list-item-title> -->
      <span class="hide-sm hide-xs"
        >Wallet Balance: <v-icon color="info">monetization_on </v-icon>
        <span> {{ refreshBalance }} </span>
        <v-btn
          icon
          :loading="loader"
          class="mx-0 px-0"
          color="green"
          width="20px"
          height="20px"
        >
          <i @click="getrefresh" class="material-icons cursor-pointer"
            >refresh</i
          >
        </v-btn>
        |</span
      >
    </div>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          color="error"
          :content="String(noOfCartItem)"
          :value="String(noOfCartItem)"
          overlap
          class="style-btn"
        >
          <v-icon v-bind="attrs" v-on="on" @click="toShoppingCart"
            >shopping_cart</v-icon
          >
        </v-badge>
      </template>
      <span>Shopping cart</span>
    </v-tooltip>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-list-item-avatar v-bind="attrs" v-on="on">
          <img
            v-if="checkImg"
            :src="userData.profileImageData"
            alt="user"
            width="50"
          />
          <img v-else :src="dummyImg" alt="dummyuser" width="50" />
        </v-list-item-avatar>
      </template>
      <v-card width="380">
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <img
                v-if="checkImg"
                :src="userData.profileImageData"
                alt="user"
                width="50"
              />
              <img v-else :src="dummyImg" alt="dummyuser" width="50" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                {{ userData.name }}
              </v-list-item-title>
              <v-list-item-subtitle>Admin of DID Panel</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list class="pt-2 pb-4">
          <v-list-item>
            <v-list-item-action class="mr-2">
              <v-icon color="primary">mail</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ userData.email }}</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-action class="mr-2">
              <v-icon color="info">monetization_on </v-icon>
            </v-list-item-action>
            <v-list-item-title>${{ refreshBalance }}</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-action class="mr-2">
              <v-icon color="green">call </v-icon>
            </v-list-item-action>
            <v-list-item-title> {{ noOfDID }} DID </v-list-item-title>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  x-small
                  class="mx-2"
                  fab
                  dark
                  to="/purchasedid"
                  color="indigo"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </template>
              <span>Purchase DID</span>
            </v-tooltip>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn small text to="/profile">
            <v-icon small left color="info">edit</v-icon> Edit Profile
          </v-btn>
          <v-btn small text @click="logout">
            <v-icon small left color="error">lock</v-icon> Logout
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
import dashboardAPI from "@/services/dashboardAPI.js";
import shoppingCartAPI from "@/services/shoppingCartAPI.js";
import profileAPI from "@/services/profileAPI.js";
import dummyImg from "../../assets/images/dummyProfile.jpeg";

export default {
  name: "app-toolbar",
  props: ["drawer", "showDrawer"],
  data: () => ({
    userDetail: {},
    showMenu: true,
    credit: 0,
    refreshBalance: 0,
    userData: {},
    cartData: {},
    cartList: [],
    noOfDID: {},
    noOfCartItem: 0,
    dummyImg: dummyImg,
    checkImg: "",
    loader: false,
  }),

  methods: {
    async getBalanceKazoo() {
      let balanceDetail = {
        accountId: this.userDetail.accountId,
      };
      try {
        let response = await dashboardAPI.getBalanceKazoo(balanceDetail);
        this.credit = response.balance;
      } catch (error) {}
    },

    async getrefresh() {
      this.loader = true;
      let userDetail = JSON.parse(localStorage.getItem("userDetail"));
      try {
        let response = await dashboardAPI.getrefresh({
          partyId: userDetail.partyId,
        });
        this.refreshBalance = response.walletBalance;
        this.loader = false;
      } catch (error) {
        this.loader = false;
      }
    },

    async getCartItem() {
      try {
        let response = await shoppingCartAPI.getCartItem();
        this.cartData = response;
        this.cartList = response.cartItemList;
        this.noOfCartItem = this.cartList.length;
      } catch (error) {}
    },

    async getUserDetail() {
      let userData = {
        accountId: this.userDetail.accountId,
        userId: this.userDetail.userId,
      };
      try {
        let response = await profileAPI.getUserDetail(userData);
        this.userData = response.userDetail;

        console.log("this.userData", this.userData);
        if (
          this.userData?.isPhoneVerified == "Y" &&
          (this.userData?.isIdentityVerified === "S" ||
            this.userData?.isIdentityVerified === "Y")
        ) {
          this.$set(this, "showMenu", false); // This ensures reactivity
          this.$forceUpdate(); // Force Vue to re-render the component
        }

        this.userData.email = response.userDetail.emailAddress.emailAddress;
        this.checkImg = response.profileImageData;
        this.userData.profileImageData = `data:image/jpg;base64,${response.profileImageData}`;
      } catch (error) {}
    },

    async getPhoneNumList() {
      let phoneNumberDetail = {
        accountId: this.userDetail.accountId,
      };
      try {
        let response = await dashboardAPI.getPhoneNumList(phoneNumberDetail);
        this.noOfDID = Object.keys(response.phonenosList.numbers).length;
      } catch (error) {}
    },

    logout() {
      localStorage.clear();
      location.href = "/";
    },

    toShoppingCart() {
      this.$router.push("/shoppingcart");
    },

    toggleDrawer() {
      this.$root.$emit("TOGGLE_DRAWER");
    },

    backToHome() {
      location.href = "/callflow";
    },
  },

  mounted() {
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    this.getBalanceKazoo();
    this.getrefresh();
    this.getUserDetail();
    this.getCartItem();
    this.getPhoneNumList();
    this.$root.$on("CHANGE_PROFILE_DATA", (data) => {
      this.userData.name = data.name;
    });

    // Listen to profile update event and refresh toolbar
    this.$root.$on("PROFILE_UPDATED", () => {
      this.getUserDetail();
      this.$forceUpdate();
    });

    this.$root.$on("MODIFY_CART", (data) => {
      this.noOfCartItem = Number(this.noOfCartItem) + Number(data.quantity);
    });
  },
};
</script>

<style scoped>
.style-btn {
  margin: 6px 8px;
}

.user-info {
  font-size: 14px;
  font-weight: bold;
}
</style>
