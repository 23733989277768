<template>
  <v-navigation-drawer
    app
    :mini-variant.sync="mini"
    v-model="drawer"
    width="256"
    color="#1d2228"
  >
    <v-list-item :class="mini && 'px-2'" class="pt-2" dark dense>
      <v-list-item-avatar>
        <img
          v-if="checkImg"
          :src="userData.profileImageData"
          alt="user"
          width="40"
          class="pointer"
          @click="showImageDialog = true"
        />
        <img
          v-else
          :src="dummyImg"
          class="pointer"
          @click="showImageDialog = true"
          alt="dummyuser"
          width="40"
        />
        <!-- <img
            :src="userData.profileImageData"
            onerror="this.src='/images/user.png';"
            style="
              border-radius: 50%;
              height: 45px;
              width: 45px;
              float: right;
              cursor: pointer;
            "
            @click="showImageDialog = true"
          /> -->
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title> {{ userData.name }} </v-list-item-title>
        <v-list-item-subtitle> DID Panel</v-list-item-subtitle>
      </v-list-item-content>
      <!-- <v-icon @click="mini = !mini">menu</v-icon> -->
    </v-list-item>
    <v-list dense dark :class="mini ? 'px-0' : 'px-3'">
      <v-divider class="pb-4"></v-divider>
      <template v-for="item in menus">
        <v-list-item
          :to="item.path"
          v-if="!item.group"
          :key="item.name"
          :disabled="
            showMenu && item.name !== 'Profile' && item.tabName != 'PurchaseDID'
          "
          class="mb-2 light-font"
        >
          <v-list-item-icon class="mr-0" v-if="item.icon">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              v-text="item.title"
              class="subtitle-text pl-3 font-weight-regular"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          v-if="item.group"
          color="white"
          ripple
          :key="item.name"
          no-action
          class="mb-1"
        >
          <template v-slot:activator>
            <v-list-item-icon class="mr-0" v-if="item.icon">
              <v-icon class="light-fonts">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="item.title"
                class="subtitle-text pl-3 light-fonts font-weight-regular"
              ></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="child in item.items"
            :key="child.name"
            :to="child.path"
            class="pl-8 light-font"
          >
            <v-list-item-icon class="mr-0" v-if="child.icon">
              <v-icon small>{{ child.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="child.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </template>
    </v-list>

    <v-dialog v-model="showImageDialog" max-width="400" persistent>
      <v-card flat class="Card-style pa-0">
        <v-card-title>
          <span class="headline">Upload Image</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-col cols="12" md="12" class="text-center">
          <v-avatar size="200">
            <img
              v-if="checkImg"
              :src="userData.profileImageData"
              onerror="this.src='/images/user.png';"
              class="pa-5"
            />
            <img
              v-else
              :src="dummyImg"
              class="pointer"
              @click="showImageDialog = true"
              alt="dummyuser"
              width="40"
            />
          </v-avatar>
        </v-col>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            elevation="0"
            @click="showImageDialog = false"
            >CANCEL</v-btn
          >
          <v-btn color="primary" text elevation="0" @click="toProfileImage">
            UPLOAD
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>
import menu from "@/side-nav/menu";
import profileAPI from "@/services/profileAPI.js";
import dummyImg from "../../assets/images/dummyProfile.jpeg";

export default {
  name: "app-drawer",
  data: () => ({
    barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    showMenu: true,
    mini: false,
    drawer: true,
    menus: [],
    userData: {},
    dummyImg: dummyImg,
    showImageDialog: false,
    checkImg: "",
  }),
  methods: {
    logout() {
      localStorage.clear();
      location.href = "/";
    },
    toProfileImage() {
      this.showImageDialog = false;
      this.$router.push("/profileimage");
    },
    async getUserDetail() {
      let userData = {
        accountId: this.userDetail.accountId,
        userId: this.userDetail.userId,
      };
      try {
        let response = await profileAPI.getUserDetail(userData);
        this.userData = response.userDetail;

        if (
          this.userData?.isPhoneVerified == "Y" &&
          (this.userData?.isIdentityVerified === "Y" ||
            this.userData?.isIdentityVerified === "S")
        ) {
          this.$set(this, "showMenu", false); // This ensures reactivity
          this.$forceUpdate(); // Force Vue to re-render the component
        }
        this.checkImg = response.profileImageData;
        this.userData.profileImageData = `data:image/jpg;base64,${response.profileImageData}`;
      } catch (error) {}
    },
  },

  created() {
    this.menus = menu.userMenu;
  },

  mounted() {
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    this.getUserDetail();
    this.$root.$on("CHANGE_PROFILE_DATA", (data) => {
      this.userData.name = data.name;
    });

    // Listen to profile update event and refresh toolbar
    this.$root.$on("PROFILE_UPDATED", () => {
      this.getUserDetail();
      this.$forceUpdate();
    });

    this.$root.$on("CHANGE_PROFILE_IMAGE", (data) => {
      this.userData.profileImageData = data.imageData;
      this.$forceUpdate();
    });
    this.$root.$on("TOGGLE_DRAWER", () => {
      this.drawer = !this.drawer;
    });
  },
};
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
.v-list-item__content {
  overflow: inherit !important;
}
.v-list-item__title {
  padding-bottom: 2px !important;
}
</style>
