<template>
  <div id="app-root">
    <template v-if="!isAuthenticated()">
      <transition>
        <keep-alive>
          <router-view></router-view>
          <v-snackbar
            :timeout="5000"
            top
            right
            :color="snackbar.color"
            v-model="snackbar.show"
          >
            {{ snackbar.text }}
          </v-snackbar>
        </keep-alive>
      </transition>
    </template>
    <template v-else>
      <v-app>
        <!-- Navigation drawer -->
        <app-drawer v-if="$route.path != '/didnPbx'"></app-drawer>
        <v-main class="page-content">
          <!-- Header toolbar -->
          <app-toolbar></app-toolbar>
          <template v-if="checkSession()"> </template>
          <v-container fluid>
            <router-view :key="$route.fullPath"></router-view>
          </v-container>
          <v-snackbar
            :timeout="5000"
            top
            right
            :color="snackbar.color"
            v-model="snackbar.show"
          >
            <b> {{ snackbar.text }}</b>
            <template v-slot:action="{ attrs }">
              <v-btn icon v-bind="attrs" @click="snackbar.show = false">
                <v-icon dark> close</v-icon>
              </v-btn>
            </template>
          </v-snackbar>
        </v-main>
      </v-app>
    </template>
  </div>
</template>

<script>
import AppDrawer from "@/components/pageHeader/app-drawer";
import AppToolbar from "@/components/pageHeader/app-toolbar";
// import ThemeSettings from "@/components/theme-settings";
import menu from "@/side-nav/menu";

export default {
  components: {
    AppDrawer,
    AppToolbar,
    // ThemeSettings
  },
  data: () => ({
    menus: [],
    snackbar: {
      show: false,
      text: "",
      color: "",
    },
  }),
  computed: {
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
  },
  methods: {
    isAuthenticated() {
      return localStorage.getItem("auth");
    },
    checkSession() {
      // console.log("this.$store.state.idleVue.isIdle",this.$store.state.idleVue.isIdle)
      if (this.$store.state.idleVue.isIdle) {
        localStorage.clear();
        this.$router.push({
          name: "Login",
        });
      }
    },
  },

  mounted() {
    this.menus = menu;
    this.$root.$on("SHOW_SNACKBAR", (data) => {
      this.snackbar = {
        show: true,
        text: data.text,
        color: data.color,
      };
    });
  },
};
</script>

<style>
</style>
