const http = require('./http');
const authToken = localStorage.getItem("authToken");

const getUserDetail = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getUserDetail?accountId=${payload.accountId}&userId=${payload.userId}&authToken=${localStorage.getItem("authToken")}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        })
    })
};

const updateProfile = (payload) => {
    return new Promise((resolve, reject) => {
        http.post('updateUserDetail', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const sendVerificationCode = (payload) => {
    return new Promise((resolve, reject) => {
        http.post('sendVerificationCode', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};


const verificationCheck = (payload) => {
    return new Promise((resolve, reject) => {
        http.post('verificationCheck', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};


const getUserSecurityList = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getUserSecurityQuestions?partyId=${payload.partyId}`).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
};

const updateSecurityQuestions = (payload) => {
    return new Promise((resolve, reject) => {
        http.post(`/updateSecurityQuestions`, payload).then((data) => {
            resolve(data);
        }).catch((err) => {
            reject(err);
        });
    });
};

const changePassword = (payload) => {
    return new Promise((resolve, reject) => {
        http.post(`/changePassword`, payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const uploadProfileImage = (payload) => {
    return new Promise((resolve, reject) => {
        http.post('uploadProfilePicImage', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getAccountsDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getAccountsDetails?authToken=${payload.authToken}&accountId=${payload.accountId}`).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
};

const updateTimezone = (payload) => {
    return new Promise((resolve, reject) => {
        http.post(`/updateTimezone`, payload).then((data) => {
            resolve(data);
        }).catch((err) => {
            reject(err);
        });
    });
};

const createEmailVerification = (payload) => {
    return new Promise((resolve, reject) => {
        http.post('createEmailVerification', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getEmailChangeHistory = (partyId) => {
    return new Promise((resolve, reject) => {
        http.get(`/getEmailChangedHistory?partyId=${partyId}`).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getSMSTrunksApi = (payload) => {
    return new Promise((resolve, reject) => {
      http.get(`/getSMSTrunks?viewIndex=${payload.viewIndex}&viewSize=${payload.viewSize}&partyId=${payload.partyId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.error("API Error:", error);
          reject(error);
        });
    });
  };

// const getSMSTrunksApi = (page, viewSize, payload) => {
//     return new Promise((resolve, reject) => {
//         http.get(`/getSMSTrunks?viewIndex=${page}&pageSize=${viewSize}`).then((response) => {
//             resolve(response);
//         }).catch((error) => {
//             reject(error);
//         });
//     });
// };


module.exports = {
    getUserDetail,
    updateProfile,
    getUserSecurityList,
    updateSecurityQuestions,
    changePassword,
    uploadProfileImage,
    getAccountsDetails,
    updateTimezone,
    createEmailVerification,
    getEmailChangeHistory,
    verificationCheck,
    sendVerificationCode,
    getSMSTrunksApi
};