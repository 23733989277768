const http = require('./http');
const authToken = localStorage.getItem("authToken");

const getProductStoreDetail = () => {
    return new Promise((resolve, reject) => {
        http.get('/getProductStoreDetails').then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getDIDHistory = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/didHistory?accountId=${payload.accountId}&authToken=${localStorage.getItem("authToken")}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        })
    })
};

const getInboundOutboundGraph = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getInboundOutboundCallsGraph?accountId=${payload.accountId}&direction=${payload.direction}&authToken=${localStorage.getItem("authToken")}`)
            .then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            })
    })
};


const getOutboundCallLimit = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getAccountLimitsDetails?accountId=${payload.accountId}&authToken=${localStorage.getItem("authToken")}`)
            .then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            })
    })
};

// Below APIs relate to Toolbar
const getPhoneNumList = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getPhoneNumList?accountId=${payload.accountId}&authToken=${localStorage.getItem("authToken")}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        })
    })
};

const getBalanceKazoo = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getBalanceKazoo?accountId=${payload.accountId}&authToken=${localStorage.getItem("authToken")}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        })
    })
};


const getrefresh = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/syncBalanceWithKazooWallet?partyId=${payload.partyId}`)
            .then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            })
    })
};


const getConnectivityList = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getConnectivityList?accountId=${payload.accountId}&authToken=${payload.authToken}`)
            .then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            })
    })
};

const addConnectivityId = (payload) => {
    return new Promise((resolve, reject) => {
        http.put('addConnectivityId', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

module.exports = {
    getrefresh,
    getBalanceKazoo,
    getPhoneNumList,
    getProductStoreDetail,
    getDIDHistory,
    getInboundOutboundGraph,
    getConnectivityList,
    addConnectivityId,
    getOutboundCallLimit
};